.ackbtn {
  background: #1c407b;
  border: 1px solid #1e3760;
  border-radius: 4px;
  opacity: 1;
  font-size: 16px;
  letter-spacing: 0.48px;
  color: #ffffff;
  padding: 15px 18px;
}
.custom-switch {
  margin-left: 31px;
}
.priority-status {
  width: 20px;
  height: 20px;
  background: #e31e24;
  border-radius: 2px;
  opacity: 1;
}
.alarm-state-dropdown {
  height: 50px;
  background-color: #f1f1ff;
  border: none;
  color: gray;
}
.priority-status-legend {
  width: 16px;
  height: 16px;
  background: #e31e24;
  border-radius: 2px;
}
.search-table-row-select {
  padding: 0 !important;
  padding-left: 20px !important;
  padding-top: 9px !important;
}

.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input:focus {
  background-color: none !important;
}
.priority-statusInprogress {
  width: 20px;
  height: 20px;
  background: #ffa500;
  border-radius: 2px;
  opacity: 1;
}
.priority-statusInprogress-legend {
  width: 16px;
  height: 16px;
  background: #ffa500;
  border-radius: 2px;
  opacity: 1;
}
.priority-statusClose {
  width: 20px;
  height: 20px;
  background: #ebeb27;
  border-radius: 2px;
  opacity: 1;
}
.priority-legend {
  font-size: 15px;
  font-weight: 500;
  margin-left: 8px;
  margin-top: -3px;
  margin-right: 13px;
}
.priority-statusClose-legend {
  width: 16px;
  height: 16px;
  background: #ebeb27;
  border-radius: 2px;
  opacity: 1;
}
.priority-status1 {
  width: 20px;
  height: 20px;
  border: 2px solid #1c407b;
  border-radius: 2px;
  opacity: 1;
}
.selectDropdown {
  padding: 0 !important;
}
.search-table-row .search-table-row1 {
  padding: 0px !important;
}
/* .alarm-table th {
  position: sticky !important;
  top: 0;
} */
.alarmpopup {
  background: #ffffff;
  box-shadow: 0px 0px 30px #0000008a;
  border-radius: 6px;
  padding: 30px;
}
.alarm-table-group table thead th {
  background-color: #fff;
  border-bottom: 0px !important;

  padding: 0px !important;
}
.alarm-table thead {
  position: sticky;
  top: 0px;
  z-index: 999;
}
.alarm-table td {
  padding: 5px;
}
table.alarm-table {
  width: 100%;
  z-index: -1;
  margin: auto;
  overflow: auto;
  border-collapse: separate;
  border-spacing: 0;
}
.alarm-table tr:nth-child(odd) {
  background-color: #f4f7fe;
}

.css-pdct74-MuiTablePagination-selectLabel {
  margin-top: 14px !important;
}
.css-levciy-MuiTablePagination-displayedRows {
  margin-top: 14px !important;
}
.css-1chpzqh {
  margin-top: 14px !important;
}
.inactive-row {
  font-weight: 700;
}
.remarksModal {
  position: absolute;
  right: 3% !important;
  margin-top: 7px;
  z-index: 99;
  box-shadow: 0px 0px 30px #0000008a;
  opacity: 1;
}
.alarmTextArea:focus-visible {
  outline: none !important;
}
.alarmData {
  position: fixed;
  top: 42%;
  left: 42%;
  transform: translate(-50px, -50px);
  z-index: 999;
  box-shadow: 0px 0px 30px #0000008a;
  border-radius: 6px;
}
.alarmCard {
  height: auto;
  width: auto;
  padding: 25px 10px;
}
.card-content {
  margin-top: 13px;
  color: #1c407b;
}
.alarmYes {
  background: #1c407b;
  border: 1px solid #1e3760;
  border-radius: 4px;
  padding: 9px 22px;
  letter-spacing: 0.48px;
  color: #ffffff;
  margin-right: 15px;
}
.alarmNo {
  background: #a9b0bd;
  border: 1px solid #a9b0bd;
  border-radius: 4px;
  padding: 9px 22px;
  letter-spacing: 0.48px;
  color: #001323;
}
.alarm-container {
  display: none !important;
  height: 638px;
  overflow: auto;
}
.search-table-row {
  /* padding: 5px 16px 5px 40px !important; */
  background: #f1f1ff;
  height: 50px;
  display: flex;
  /* min-width: 400px; */
  max-width: 100%;
}
.responsive-header-design{
  display: none;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .alarmData {
    position: fixed;
    top: 50%;
    left: 34%;
    transform: translate(-50px, -50px);
    z-index: 99;
    box-shadow: 0px 0px 30px #0000008a;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1536px) {
  .alarmData {
    position: fixed;
    top: 55%;
    left: 42%;
    transform: translate(-50px, -50px);
    z-index: 99;
    box-shadow: 0px 0px 30px #0000008a;
    border-radius: 6px;
  }
}

@media (max-width: 576px) {
  .priority-status {
    width: 16px;
    height: 16px;
  }
  .priority-statusInprogress {
    width: 16px;
    height: 16px;
  }
  .priority-statusClose {
    width: 16px;
    height: 16px;
  }
  .alarm-table {
    display: none !important;
  }
  .alarm-legend {
    display: none !important;
  }
  .alarm-container {
    display: block !important;
  }
  .ackbtn {
    font-size: 14px;
    padding: 0px;
    width: 113px;
    height: 36px;
  }
  .alarm-wrapper {
    padding: 20px 20px 0px !important;
  }
  .alarm-excel-img {
    width: 38px;
    height: 38px !important;
  }
  .alarm-start-date {
    width: 120px;
  }
  .alarm-end-date {
    width: 120px;
  }
  .alarm-input-label span {
    font-size: 14px;
    margin-right: 5px;
    font-weight: 600;
  }
  .alarm-drodown {
    width: 56px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #d3dae5;
    border-radius: 4px;
    color: #d3dae5;
  }
  .alarm-input-dropdown {
    padding: 0px 9px !important;
    width: 75%;
    border-radius: 4px;
  }
  .alarm-state-dropdown {
    background-color: #f1f1ff;
    color: gray;
    height: 34px !important;
    border-radius: 4px;
    background: #ffff;
    border: 1px solid #D3DAE5;
    font-size: 14px;
    padding: 0px 3px;
    margin-left: -20px;
}
.input-elevated{
  font-size: 14px;
  line-height: 1.5;
  border: none;
  background: #FFFFFF;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 20 20'><path fill='%23838D99' d='M13.22 14.63a8 8 0 1 1 1.41-1.41l4.29 4.29a1 1 0 1 1-1.41 1.41l-4.29-4.29zm-.66-2.07a6 6 0 1 0-8.49-8.49 6 6 0 0 0 8.49 8.49z'></path></svg>");
  background-repeat: no-repeat;
  background-position: 10px 10px;
  background-size: 17px 17px;
  border-radius: 5px;
  border: 1px solid #D3DAE5;
  width: auto;
  padding: .5em 1em 0.4em 2.5em;
  } 
  .responsive-header-design{
    display: block !important;
  }
  .alarm-header{
    display: none !important;
  }
  .alrm-date-table{
    padding-top: 0 !important;
  }
  .alarm-table-pagination p{
    font-size: 12px !important;
  }
  .alarm-table-pagination li{
    padding: 0;
    font-size: 10px !important;
  }
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    margin-top: -27px;
}
.custom-switch {
  margin-left: 2px;
}
}
@media(min-width:577px) and (max-width:767px){
  .ackbtn{
    padding: 6px 18px;
    font-size: 15px;
  }
  .alarm-datepicker{
    margin-bottom: 0px;
  }
  .excel-alarm{
    height: 36px !important;
    width: 36px !important;
  }
}