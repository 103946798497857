.smarttag-machine-analytics-main-container{
    display: flex;
    justify-content: space-between;
}
.smartTag-boxes {
    background-color: #fff;
    min-height: 943px;
    border-radius: 6px;
    padding: 10px;
}
.smartTag-report-taglist-unit {
height: 560px;
overflow: auto;
}