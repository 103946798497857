@media (max-width: 576.98px) {
  .page-btn-consumption {
    width: unset;
    margin-top: -19px;
    gap: 2px;
  }

  .consumption-interval-toggle {
    width: 70px;
    height: 26px;
    font-size: 12px;
  }

  .time-desc {
    margin-left: 8px;
    margin-right: 10px;
    margin-top: 7px;
    font-size: 12px;
    font-weight: 400;
  }

  .consumption-avg-toggle {
    font-size: 12px !important;
  }

  .average-toggle-switch {
    margin-right: -11px !important;
    margin-top: -4px;
  }

  .source-boxes {
    margin-right: 5px !important;
  }

  .source-boxes h6 {
    font-size: 12px;
  }

  .source-consumption-table th {
    font-size: 12px !important;
    padding: 10px !important;
  }

  .source-consumption-table td {
    font-size: 12px !important;
    font-weight: 500 !important;
  }

  .source-consumption-table th:first-child {
    width: 130px !important;
  }

  .source-table {
    display: none !important;
  }
}

@media (min-width: 200px) and (max-width: 360px) {
  .avg-switch {
    flex-wrap: wrap;
  }
}

@media (min-width: 577px) and (max-width: 1920px) {
  .source-consumption-table {
    display: none !important;
  }
}

.source-consumption-table td {
  padding: 10px !important;
  border: none !important;
  border-right: 1px solid #cbc3c3 !important;
  font-size: 16px;
  font-weight: 700;
}

.source-consumption-table td:last-child {
  border-right: none !important;
}

.source-consumption-table th:last-child {
  border-right: none !important;
}

.source-consumption-table th {
  border: none !important;
  border-right: 1px solid #cbc3c3 !important;
  background-color: #f5f5f5;
  font-size: 15px;
  font-weight: 600;
}

.source-consumption-table tr {
  border: none !important;
}

.source-consumption-table {
  border: none !important;
  border-radius: 0 !important;
}

.source-consumption-table tr:nth-child(even) {
  background-color: #f5f5f5;
}

@media (min-width: 200px) and (max-width: 450px) {
  .page-back-btn {
    margin-top: 0 !important;
  }

  .page-back-btn span {
    font-size: 16px;
    letter-spacing: 0.4px;
  }

  .compact-sec {
    margin-top: 9px;
    width: 100%;
  }
}

@media(max-width:576px) {

  .sticky-page {
    position: sticky;
    margin-bottom: 25px;
  }

  .consumption-fixHeight {
    height: 500px;
    overflow: scroll;
  }
}