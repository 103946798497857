.processDashboar-meterCard {
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 2px;
}

.processDashboard-chartText {
  text-transform: uppercase;
  text-align: center;
  font-size: 16px;
  margin-top: 10px;
  color: #001323;
  letter-spacing: 0px;
  font-weight: 600 !important;
}

.canvas-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
}

/* .marquee-gradient-color{
    --gradient-color: rgba(248, 251, 253, 1), rgba(248, 251, 253, 0);
    --gradient-width: 200px;
} */
.overlay {
  background-color: #ffff !important;
  top: 0 !important;
}

.device-desc {
  font-size: 16px;
  color: #001323;
  letter-spacing: 0px;
  font-weight: 600 !important;
}

.device-number {
  font-size: 30px !important;
  letter-spacing: 0px;
  color: #001323;
  font-weight: 700 !important;
}

.process-card {
  display: flex;
  gap: 10px;
}

.unit {
  text-align: end;
  color: rgb(0, 19, 35);
  /* margin-top: -6px !important; */
  letter-spacing: 0.42px;
  font-size: 15px !important;
}

.process-card-chart {
  display: flex;
  gap: 15px;
}

.overViewButton {
  background: #ffffff;
  box-shadow: 0px 6px 12px #67676747;
  border: 1px solid #e31e24;
  border-radius: 4px;
}

.Process-dashboard-btn {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #67676747;
  border: 1px solid #e31e24;
  border-radius: 4px;
  width: 100%;
  padding: 20px;
  color: #001323 !important;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  font-weight: 700;
}

.active-process-dashboard {
  background: #e31e24 0% 0% no-repeat padding-box;
  border: 1px solid #e31e24;
  border-radius: 4px;
  width: 100%;
  padding: 20px;
  color: #ffffff !important;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  font-weight: 700;
}

.graph-box {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 40px; */
}

.Process-dashboard-btn:hover {
  text-decoration: none;
}

.process-header-btn-date {
  /* width: 37%; */
  /* margin-right: 10px; */
}

.process-header-btn-month {
  /* width: 37%; */
}

.graph-date {
  display: flex;
  gap: 10px;
}

.barChart-background {
  padding-top: 10px;
}

.slider-title {
  margin-left: 10px;
  /* min-height: 100px; */
  /* padding-left: 30px; */
  /* border-right: 4px solid  #5A8A8A; */
  padding-left: 12px;
  /* border-left: 4px solid #5A8A8A; */
}

.sliderColor1 {
  border-left: 6px solid #aec7a6;
}

.sliderColor2 {
  border-left: 6px solid #5a8a8a;
}

.sliderColor3 {
  border-left: 6px solid #dec591;
}

.sliderColor4 {
  border-left: 6px solid #e9b9aa;
}

.sliderColor5 {
  border-left: 6px solid #8cb9c0;
}

.sliderColor6 {
  border-left: 6px solid #7892b5;
}

.sliderColor7 {
  border-left: 6px solid #febb22;
}

.sliderColor8 {
  border-left: 6px solid #002b2a;
}

.sliderColor9 {
  border-left: 6px solid #c99c00;
}

.sliderColor10 {
  border-left: 6px solid #eb9e79;
}

.sliderColor11 {
  border-left: 6px solid #69927a;
}

.sliderColor12 {
  border-left: 6px solid #872b81;
}

.sliderColor13 {
  border-left: 6px solid #f06292;
}

.sliderColor14 {
  border-left: 6px solid #0eb520;
}

.sliderColor15 {
  border-left: 6px solid #cd4d4a;
}

.sliderColor16 {
  border-left: 6px solid #123782;
}

.sliderColor17 {
  border-left: 6px solid #544632;
}

.sliderColor18 {
  border-left: 6px solid #94655b;
}

.silder--title {
  margin-bottom: 0 !important;
  font-size: 13px;
}

.silder--data {
  margin-bottom: 0 !important;
  font-size: 30px;
  font-weight: 700;
  margin-top: -7px;
}

.silder--unit {
  margin-bottom: 0 !important;
  /* margin-left: 65px !important; */
  font-size: 14px;
  color: #001323;
  letter-spacing: 0.42px;
  margin-top: -8px;
  /* text-align: end; */
  /* margin-right: 20px; */
  margin-right: 36px;
}

.slider-data-div {
  margin-top: 38px;
  padding-left: 20px;
  padding-right: 20px;
}

.doughnut-chart {
  margin-left: auto;
  margin-right: auto;
}

.not-found {
  transform: translate(5px, -8px) !important;
}

@media (min-width: 767px) and (max-width: 960px) {
  .process-card {
    display: block !important;
    gap: 15px;
  }

  .process-grid {
    margin-top: 9px !important;
  }

  .process-card-chart {
    display: block !important;
    gap: 15px;
  }

  .Analytics-deviceModal-popup {
    left: 22% !important;
  }

  .slider-data-div {
    margin-top: 5px;
  }

  .doughnut-chart {
    margin-top: 12px !important;
  }
}

.doughnut-chart {
  width: 100%;
  height: 250px;
  position: relative;
}

.doughnut-chartText {
  font-size: 22px;
  font-weight: bold;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.doughnut-chartText-small {
  /* font-size: 10px; */
  position: absolute;
  top: 49%;
  left: 54%;
  transform: translate(-50%, -50%) 7;
}

/* .processdasboard-barChart {
    min-height: 300px;
} */

@media (min-width: 961px) and (max-width: 1024px) {

  .graph-box {
    flex-direction: column;
  }

  .Analytics-deviceModal-popup {
    left: 28%;
  }

  .slider-data-div {
    margin-top: 17px;
  }

  .doughnut-chart {
    margin-top: 8px !important;
  }
}

/* @media (min-width: 899.98px) and (max-width: 1024px){
  .process-card {
    display: block !important;
    gap: 15px;
  }
  .process-grid{
    margin-top: 9px !important;
  }
  .process-card-chart{
    display: block !important;
    gap: 15px;
  }
  .process-header-btn-date{
    width: 38%;
    margin-right: 10px;
  }
  .process-header-btn-month{
    width: 38%;
  }
  .graph-box{
    flex-direction: column;
    }
} */
.compact-btn{
  display: none;
  padding: 8px;
}

.hikar-text p{
  font-size: 14px;
  line-height: 23px;
}
.confi-btn{
  display: none;
}
@media (min-width: 1025px) and (max-width: 1300px) {
  .Analytics-deviceModal-popup {
    left: 31% !important;
  }

  .slider-data-div {
    margin-top: 12px;
  }

  .doughnut-chart {
    margin-top: 11px !important;
  }

  .processdasboard-barChart {
    margin-top: 44px !important;
  }
}

@media (min-width: 1300px) and (max-width: 1536px) {
  .Analytics-deviceModal-popup {
    max-height: 500px;
    overflow: auto;
    left: 31% !important;
  }

  .submitCancleBtn {
    margin-bottom: 20px;
  }

  .slider-data-div {
    margin-top: 11px;
  }

  .doughnut-chart {
    margin-top: 10px !important;
  }

  .processdasboard-barChart {
    margin-top: 44px !important;
  }

}

@media (min-width: 431px) and (max-width: 576px) {
  .tiles-card {
    /* width: 190px; */
    height: 80px;
  }

  .speedometer-img {
    width: 54px;
    height: 54px;
    margin-top: -10px;
  }

  .device-desc {
    font-size: 10px !important;
    letter-spacing: 0px;
    color: #001323;
  }

  .device-number {
    font-size: 20px !important;
    margin-top: -6px !important;
  }

  .processDashboar-meterCard {
    padding-top: 0px !important;
  }

  .unit {
    font-size: 10px !important;
    margin-top: -6px !important;
  }

  .grid-box {
    padding: 5px !important;
  }

  .grid-container-box {
    position: sticky;
  }

  .process-header-btn-date {
    width: 120px;
    height: 27px;
  }

  .process-header-btn-month {
    width: 120px;
    height: 27px;
  }

  .process-btn-grid {
    position: relative;
    bottom: 34px;
    background: #F1F1FF;
    z-index: 9999;

  }

  .grid-container-box {
    position: sticky;
    margin-bottom: 4px !important;
  }

  .process-card-chart {
    height: 435px;
    overflow: auto;
  }
}

@media (min-width: 375px) and (max-width: 430px) {
  .processDashboar-meterCard {
    gap: 6px;
  }

  .tiles-card {
    /* width: 160px; */
    height: 80px;
  }

  .speedometer-img {
    width: 42px;
    margin-top: -10px;
  }

  .device-desc {
    font-size: 10px !important;
    letter-spacing: 0px;
    color: #001323;
  }

  .device-number {
    font-size: 16px !important;
    margin-top: -6px !important;
  }

  .processDashboar-meterCard {
    padding-top: 0px !important;
  }

  .unit {
    font-size: 10px !important;
    margin-top: -6px !important;
  }

  .grid-box {
    padding: 5px !important;
  }

  .grid-container-box {
    position: sticky;
    margin-bottom: 4px !important;
  }

  .process-card-chart {
    height: 474px;
    overflow: auto;
  }

}

@media(max-width:374px) {
  .processDashboar-meterCard {
    gap: 0px;
  }

  .tiles-card {
    height: 90px;
    padding: 0px 10px !important;
  }

  .speedometer-img {
    width: 42px;
  }

  .device-desc {
    font-size: 10px !important;
  }

  .device-number {
    font-size: 20px !important;
  }

  .unit {
    font-size: 10px !important;
  }
}

@media(max-width:999px) {
  .graph-box {
    justify-content: space-between;
  }

  .graph-date {
    /* gap: 12px; */
  }
}


@media(max-width:899px) {
  .graph-box {
    display: flex;
    justify-content: space-between;
  }

  .graph-date {
    gap: 10px;
  }
}

@media(max-width:767px) {
  .graph-box {
    justify-content: space-between;
  }

  .graph-date {
    gap: 8px;
  }
}

@media(max-width:576px) {
  .graph-box {
    justify-content: space-between;
    gap: 6px;
  }

  .graph-date {
    gap: 5px;
  }

  .Process-dashboard-btn {
    font-size: 12px;
    padding: 12px;
  }

  .active-process-dashboard {
    font-size: 12px;
    padding: 12px;
  }
  .device-number.long-tag{
    font-size: 11px !important;
  }
  /* .process-dashboard-compact{
    display: block !important;
  } */


}
@media(max-width:599.98px){
  .compact-btn{
    display: block !important;
    /* margin-bottom: 20px ; */
    font-size: 14px;
    border-radius: 4px;
  }
  .confi-btn{
    display: block !important;
    margin-bottom: 20px ;
    width: 130px;
    height: 36px;
    font-size: 14px !important;
    padding: 0 !important;
    background: white !important;
  }
  .product-donught-charttext{
    top: 70% !important;
    left: 50% !important; 
  }
  .product-donught-chartUnit{
    top: 72% !important;
    left: 59% !important;
  }
  .pro-donught-text{
    top: 20% !important;
    left: 48% !important;
  }
  .pro-donught-unit{
    top: 22% !important;
    left: 56% !important;
  }

}
.process-dashboard-compact{
  display: none;
}
@media(max-width:599.98){
  .process-dashboard-compact{
    display: block;
  }
}
@media(min-width: 537px) and (max-width: 568px){
  .product-donught-charttext{
    top: 40% !important;
    left: 76% !important;
  }
  .product-donught-chartUnit {
    top: 43% !important;
    left: 83% !important;
}
.pro-donught-text{
  top: 40% !important;
  left: 24% !important;
}
.pro-donught-unit {
  top: 44% !important;
  left: 30% !important;
}
}
@media (min-width : 569px) and (max-width: 600px){
  .product-donught-charttext{
    top: 40% !important;
    left: 76% !important;
  }
  .product-donught-chartUnit {
    top: 43% !important;
    left: 83% !important;
}
.pro-donught-text{
  top: 40% !important;
  left: 24% !important;
}
.pro-donught-unit {
  top: 44% !important;
  left: 30% !important;
}
}
@media (min-width : 601px) and (max-width : 766px){
  .product-donught-charttext{
    top: 39% !important;
    left: 76% !important;
  }
  .product-donught-chartUnit {
    top: 43% !important;
    left: 83% !important;
}
.pro-donught-text{
  top: 40% !important;
  left: 24% !important;
}
.pro-donught-unit {
  top: 44% !important;
  left: 30% !important;
}
}
/* @media(max-width:1024px){
  .compact-btn{
    display: block !important;
    margin-bottom: 20px ;
    font-size: 14px;
    border-radius: 4px;
  }
  .confi-btn{
    display: block !important;
    margin-bottom: 20px ;
    width: 130px;
    height: 36px;
    font-size: 14px !important;
    padding: 0 !important;
    background: white !important;
  }
} */
@media (max-width: 1536px) {
  .device-number.long-tag {
    font-size: 17px !important;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}