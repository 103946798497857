/* .centerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
} */

.vms-Card{
  display: flex;
  justify-content: center;
}

.popup-container {
  display: flex;
  justify-content: center;
  align-items: center;
   top: 0;
  left: 0;
  width: 100%;
  height: 100%;

}
.Hikemm-popup{
  display: flex;
    width: 74%;
    margin-left: auto;
    margin-right: auto;
}
.popup {
   padding: 20px 10px;
  border-radius: 5px;
  display: flex;
  /* Add any other styles you need for your popup */
}
/* Add other styles you have in your existing CSS file */

.subscriptionTitle-wrap {
  display: flex;
  align-items: center;
  padding-top: 20px;
}

.subscriptionTitle-wrap .backArrow {
  cursor: pointer;
}

.subscription-header {
  display: flex;
  align-items: center !important;
  margin-top: 20px;
  margin-bottom: 20px;
}

.subscriptionCard {
  position: relative;
  height: auto;
  width: 450px !important;
  cursor: pointer;
  /* width: auto !important; */
}

.subscriptionCard-Image {
  position: relative;
  width: 100%;
  height: 225px;
}

.imageWrap {
  position: absolute;
  /* top: 40%; */
  /* top: 34%; */
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 50px;
  border-radius: 50%;
  padding: 60px;
  box-shadow: 0px 12px 20px #00000029;
}
.imageWrap1 {
  position: absolute;
  /* top: 34%; */
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 50px;
  border-radius: 50%;
  padding: 60px;
  box-shadow: 0px 12px 20px #00000029;
}

.subscriptionCard-Icon {
  position: sticky;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 14%;
  margin-bottom: 30px
}
.subscriptionCard-Icon1 {
  position: absolute;
  top: 34%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-bottom: 30px
}
.subscriptionCard-Icon2{
  position: absolute;
  top: 34%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-bottom: 30px;
  width:60px;
  height: auto;
}
.subscriptionContent {
  /* padding-top: 50px; */
}

.subscription-title {
  text-align: center;
  color: #001323;
  font-weight: 400 !important;
  padding-top: 64px;
}
.subscription-title h5{
  font-weight: 700 !important;
}
.subscription-title h5{
    font-weight: 700 !important;
}

.subscription-text {
  text-align: center;
}

.subscription-details {
  color: #001323;
  font-weight: 400 !important;

}

.checkIconbg {
  background-color: transparent !important;
}

.checkIcon {
  background-color: #50C878;
  border-radius: 50%;
}

@media(min-width:1640px) and (max-width:1895px) {
  .subscriptionCard {
      width: auto !important;
  }
}

@media(min-width:1025px) and (max-width:1640px) {
  .subscriptionCard {
      width: 408px !important;
  }
}

@media(min-width:899.98px) and (max-width:1024px) {
  .subscriptionCard {
      width: auto !important;
  }
  .subscriptionCard-Icon {
      left: 50%;
      transform: translate(-50%, -50%);
      margin-bottom: 30px;
  }
  .imageWrap {
      position: absolute;
      /* top: 35%; */
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      width: 50px;
      border-radius: 50%;
      padding: 60px;
      box-shadow: 0px 12px 20px #00000029;
  }
  .imageWrap1 {
      position: absolute;
      top: 35%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      width: 50px;
      border-radius: 50%;
      padding: 60px;
      box-shadow: 0px 12px 20px #00000029;
  }
  .subscriptionCard-Icon1 {
      position: absolute;
      top: 35%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-bottom: 30px;
  }
  .subscription-title {
      text-align: center;
      color: #001323;
      font-weight: 600 !important;
      padding-top: 51px;
  }
}

@media (min-width: 767px) and (max-width: 899.98px) {
  @supports (width: -webkit-fill-available) {
    /* Styles specific to WebKit browsers (like Safari on iPad) */
    .page-wraper{
      margin-bottom: 300px;
    }
    

  }
  .popup-flex{
    display: flex;
    flex-direction: column;
    
  }
  .subscriptionCard {
      width: 450px !important;
      padding-bottom: 24px;
  }
  .subscriptionCard-Icon {
      left: 50%;
      transform: translate(-50%, -50%);
      margin-bottom: 30px;
  }
  .subscriptionCard-Icon1 {
    
      top: 34%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-bottom: 30px;
  }
  .imageWrap {
      position: absolute;
      /* top: 37%; */
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      width: 50px;
      border-radius: 50%;
      padding: 60px;
      box-shadow: 0px 12px 20px #00000029;
  }
  .imageWrap1 {
      position: absolute;
      top: 34%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      width: 50px;
      border-radius: 50%;
      padding: 60px;
      box-shadow: 0px 12px 20px #00000029;
  }
  .subscription-title {
      text-align: center;
      color: #001323;
      font-weight: 600 !important;
      padding-top: 51px;
  }
}
