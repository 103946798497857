.headerWrap {
  background-color: #fff !important;
  box-shadow: 0px 2px 0px #a7a7a729 !important;
}
.configration-btn {
  background: #f0f0fe;
  border: 1px solid #aebcd2;
  border-radius: 4px;
  opacity: 1;
  padding: 7px 16px;
  letter-spacing: 0px;
  color: #001323;
  font-size: 16px;
  font-weight: 500;
}
.alarm-btn {
  background: #f0f0fe;
  border-radius: 4px;
  opacity: 1;
  padding: 8px 17px;
  border: none;
  font-size: 16px;
  font-weight: 500;
}

.alarm-button {
  background: #e31e24;
  color: #ffffff;
  border-radius: 4px;
  opacity: 1;
  padding: 8px 17px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  /* margin-left: -15px; */
}
.compact-dropdown{
  padding: 8px;
}
.headerLogo {
  width: auto;
  border-right: 1px solid #a7a7a787;
  padding-right: 35px;
  padding-left: 25px;
  height: 50px;
}

.toolbarWrap {
  justify-content: space-between;
  align-items: center;
  height: 74px;
}

.headerToolbar-wrap {
  display: flex;
  gap: 25px;
  align-items: center;
}

.headerTitle-wrap {
  display: flex;
  gap: 10px;
  align-items: center;
  padding-right: 35px;
  padding-left: 25px;
  cursor: pointer;
  height: 75px;
}

.headerTitle-wrap .headerTitle {
  font-size: 24px;
  font-weight: 600;
  color: #001323;
  padding-left: 20px;
  margin: 0;
}

.profileToggle-menu {
  display: flex;
  gap: 10px;
  align-items: center;
  padding-right: 35px;
  padding-left: 25px;
  border-left: 1px solid #a7a7a787;
  cursor: pointer;
  height: 75px;
}

.profileToggle-menu p {
  margin-bottom: 0;
  font-size: 16px;
  color: #001323;
  font-weight: 700;
}

.profileToggle-menu .ToggleIcon {
  height: 16px;
  color: #001323;
  position: absolute;
  right: 0;
}

.dateTime-wrap p {
  color: #001323;
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 500;
}

.profileItem {
  display: flex;
  gap: 16px;
  align-items: center;
}

.profileItem .profile-image {
  height: 60px !important;
  width: 60px !important;
}

.profileItem .profileDetail p.userName {
  margin: 0;
  font-size: 22px;
  font-weight: 700;
  color: #001323;
}

.profileItem .profileDetail p.userEmail {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: #001323;
}

.menuItem {
  color: #001323;
  font-weight: 500;
}

/* ========== user profile model ==========  */

.profile-item {
  display: flex;
  gap: 16px;
  align-items: center;
}

.profile-item .profile-image {
  height: 60px !important;
  width: 60px !important;
}

.profile-item .profile-detail p.userName {
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  color: #001323;
}

.profile-item .profile-detail p.userEmail {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #001323;
  /* color: rgb(84, 108, 138) */
}

.menu-item {
  color: #273444;
  font-size: 14px;
  font-weight: 400;
}

.menu-item svg {
  fill: #051d3a;
}

.menu-item li {
  padding: 20px;
}

@media(max-width:1024px){
  .expandScreen-btn {
    display: none;
  }
  /* .compact-dropdown{
    display: none;
  }
  .configration-btn{
    display: none;
  } */
  
}

@media (min-width: 768px) and (max-width: 912px) {
  .headerTitle-wrap {
    padding-left: 0;
  }
  .headerLogo {
    padding-left: 0px;
    padding-right: 16px;
  }
  .headerTitle-wrap .headerTitle {
    font-size: 20px;
    padding-left: 0;
  }
  .expandScreen-btn {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .profileName,
  .dateTime-wrap {
    display: none;
  }
  .expandScreen-btn {
    display: none;
  }

}
@media(max-width:599.98px){
  .compact-dropdown{
    display: none;
  }
  .configration-btn{
    display: none;
  }
}
@media (max-width: 599.98px) {
  .expandScreen-btn img,
  .notification-btn img {
    height: 20px;
    width: 20px;
  }
  .headerLogo {
    height: 38px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .toolbarWrap {
    height: 64px;
  }
  .headerToolbar-wrap {
    gap: 10px;
    height: 30px;
  }
  .expandScreen-btn {
    display: none;
  }

  .configration-btn{
    display: none;
  }
  .headerTitle-wrap{
    padding-left: 0px;
    padding-right :0px
  }
  .headerTitle-wrap .headerTitle{
    padding-left: 0px;
    font-size: 21px;
  }
  .profileToggle-menu{
    padding-left: 0px;
    padding-right: 0px;
    border-left: none;
  }
  .alarm-button{
    font-size: 13px;
    width: 58px;
    height: 30px;
    padding: 0;
  }
  .profile-toggle-img{
    height: 30px !important;
    width: 30px !important;
  }
  .profileToggle-menu .ToggleIcon {
   display: none;
  }

}

@media(min-width:577px) and (max-width:1024px){
  .headerWrap{
    width: 100%;
    overflow-x: auto;
  }
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: transparent;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb:horizontal {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb:horizontal:hover {
    background-color: transparent;
  }
  .headerLogo{
    padding: 0 !important;
  }
  .profileToggle-menu .ToggleIcon{
    display: none;
  }
}