.page-back-btn {
  color: #001323;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 14px;
  cursor: pointer;
}

.page-header .header-btn-group {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 0;
  flex-wrap: wrap;
}

.overviewReport {
  background: #ffffff;
  border: 1px solid #475467;
  border-radius: 4px;
  padding: 10px;
  font-size: 15px;
}

.spinner-container {
  position: relative;
  /* Add any other styles for the container */
}

.notFoundDataImg {
  margin-left: auto;
  margin-right: auto;
  display: block;
  transform: translate(-50px, -8px);
}

.notFoundDataImgPie {
  margin-left: auto;
  margin-right: auto;
  display: block;
  transform: translate(-50px, -4px);
}

.notFoundDataImgReport {
  margin-left: auto;
  margin-right: auto;
  display: block;
  transform: translate(-50px, 100px);
}

.notFoundDataImgVmsOverView {
  margin-left: auto;
  margin-right: auto;
  display: block;
  transform: translate(-48px, 82px);
}

.overView-data-table {
  min-width: 650px;
  border-collapse: separate !important;
  border-spacing: 0;
}

.overView-data-table td:first-child,
.overView-data-table td:nth-child(2) {
  position: sticky;
  left: 0;
  background-color: #fff;
  border-collapse: separate;
  /* Add a higher z-index value for the sticky first child */
  z-index: 0;
}

.MuiPaper-root.MuiTableContainer-root.table-overview-data.MuiPaper-elevation1.MuiPaper-rounded {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 99;
  overflow-x: unset !important;
}

.overviewtable-wrap {
  margin-top: 28px;
}

.overView-data-table td:nth-child(2) {
  left: 84px;
}

.overView-data-table tr:nth-child(even) td {
  background-color: #fff;
}

.overView-data-table tr:nth-child(odd) td {
  background-color: #f4f7fe;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.configrationOverviewTable td {
  padding: 5px;
}

.configrationOverviewTable th {
  padding: 15px 19px;
}

.addButton {
  background: #1c407b;
  border: 1px solid #1e3760;
  border-radius: 4px;
  color: white;
  padding: 12px 27px;
}

.spinner-graph {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* z-index: 1; */
}

.Analytics-tabs {
  background: #1c407b;
  border: 1px solid #103168;
  color: white;
  padding: 10px;
  width: 200px;
  font-weight: 500;
  font-size: 18px;
}

.Reports-tabs {
  background: white;
  border: 1px solid #103168;
  color: #103168;
  padding: 10px;
  width: 200px;
  font-weight: 500;
  font-size: 18px;
}

.Analytics-tabs-overview {
  background: #1c407b;
  border: 1px solid #103168;
  color: white;
  padding: 10px;
  width: 200px;
  font-weight: 500;
  font-size: 15px;
}

.Reports-tabs-overview {
  background: white;
  border: 1px solid #001323;
  color: #001323;
  padding: 10px;
  width: 200px;
  font-weight: 500;
  font-size: 16px;
}

.form-input-class option {
  padding: 10px;
}

.MuiTab-textColorInherit.Mui-selected {
  background: #1c407b;
  border: 1px solid #103168;
  color: #fff;
}

.AGG-dropdown {
  margin-top: 17px;
  margin-right: 9px;
  color: #001323;
  font-size: 16px;
  font-weight: 400;
}

.consumerData-box {
  background-color: #fff;
  min-height: 680px;
  border-radius: 6px;
  padding: 10px;
}

.consumer-text-disc {
  background-color: #f5f5f5;
  padding: 6px 10px;
}

.datepicker-color .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #c0255f !important;
}

.datepicker-color .MuiOutlinedInput-notchedOutline.css-igs3ac {
  border-color: #c0255f !important;
}

.second-datepicker-color .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #e66595 !important;
}

.second-datepicker-color .MuiOutlinedInput-notchedOutline.css-igs3ac {
  border-color: #e66595 !important;
}

.datepicker-color
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-1bn53lx {
  color: #c0255f !important;
}

.datepicker-color .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  color: #c0255f !important;
}

.second-datepicker-color
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-1bn53lx {
  color: #e66595 !important;
}

.second-datepicker-color .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  color: #e66595 !important;
}

.datepicker-color .css-1ald77x {
  color: #c0255f !important;
}

.datepicker-color .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  color: #c0255f !important;
}

.second-datepicker-color .css-1ald77x {
  color: #e66595 !important;
}

.second-datepicker-color .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  color: #e66595 !important;
}

.datepicker-color .css-p0rm37 {
  color: #c0255f !important;
}

.datepicker-color .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #c0255f !important;
}

.second-datepicker-color .css-p0rm37 {
  color: #e66595 !important;
}

.second-datepicker-color .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #e66595 !important;
}

.horizontal-line {
  height: 10px;
  background-color: #f5f5f5;
  /* width: 60%; */
  margin: 0 auto;
}

.consumer-text-disc1 {
  background-color: #f5f5f5;
  padding: 8px;
}

.consumer-text {
  background-color: #efeffd;
  padding: 6px;
}

.consumer-text-disc h6 {
  font-size: 14px;
}

.add-date {
  display: -webkit-inline-box;
}

.Title {
  font-size: 12px !important;
}

.search-textfield {
  padding-top: 13px !important;
  width: 100%;
}

.furnace {
  /* max-height: 150px; */
  max-height: 173px;
  overflow: auto;
}

.device-units {
  /* max-height: 100px; */
  max-height: 112px;
  overflow: auto;
}

.export-btn {
  background-color: #001323;
  color: #fff;
  border-radius: 4px;
  padding: 15px;
  width: 100%;
  margin-top: 11px;
}

.overViewTableData tr:nth-child(odd) {
  background-color: #f4f7fe;
}

.overViewTableData td {
  padding: 5px 20px;
}

table.MuiTable-root.overView-data-table.overView-table-data1 td {
  padding: 4px 24px !important;
}

table.MuiTable-root.overView-data-table.overView-table-data1 th {
  padding: 4px 24px !important;
}

.overViewTableData th {
  padding: 0px 18px;
}

.end-date-picker {
  margin-left: 17px !important;
}

.analytics-tabs-btn {
  display: flex;
}

.analytics-aggregation {
  display: flex;
}

.analysis-tabs-btn {
  justify-content: space-between;
  width: 100%;
  display: flex;
  padding-top: 11px;
}

.analysis-tabs-btn-analytics {
  justify-content: space-between;
  width: 100%;
  display: flex;
  padding-top: 11px;
}

.Grid-text1 {
  /* padding: 4px 13px; */
  /* background-color: #efeffd; */
}

.overview-table td {
  /* padding: 10px 13px !important; */
  padding: 0;
  border: none !important;
  border-right: 1px solid #cbc3c3 !important;
  font-size: 16px;
  font-weight: 700;
}

/* .overview-table td:first-child{
  padding: 0px 0px !important;
} */
table.MuiTable-root.overview-table.overview-table1.css-rqglhn-MuiTable-root td {
  padding: 0;
}

.zoomchart-td {
  padding-left: 0 !important;
}

.overview-table td:last-child {
  border-right: none !important;
}

.overview-table th:last-child {
  border-right: none !important;
}

.overview-table th {
  border: none !important;
  border-right: 1px solid #cbc3c3 !important;
  background-color: #f5f5f5;
  font-size: 16px;
}

.overview-table tr {
  border: none !important;
}

.overview-table {
  border: none !important;
  border-radius: 0 !important;
}

.overview-table tr:nth-child(even) {
  background-color: #f5f5f5;
}

.grid-switch-unit span {
  float: right !important;
}

.overview-zoom-box {
  width: 100%;
  height: 727px;
  background: white;
  border-radius: 6px;
  box-shadow: 0px 0px 20px #6d6d6d1a;
}

.Title-desc {
  margin-bottom: 0 !important;
}

.consumption-switch1 {
  background-color: red;
}

.consumption-switch2 {
  background-color: green;
}

.consumption-switch3 {
  background-color: yellow;
}

.consumption-switch4 {
  background-color: red;
}

/* div.Grid-text:nth-child(1) {
  border-left: 4px solid #9b64b7;
  margin-bottom: 20px;
} */
/* div.Grid-text:nth-child(2) {
  border-left: 4px solid #e579a0;
}
div.Grid-text:nth-child(3) {
  border-left: 4px solid #fea968;
}
div.Grid-text:nth-child(4) {
  border-left: 4px solid #fea968;
}
div.Grid-text:nth-child(5) {
  border-left: 4px solid #9b64b7;
} */
.doughnut-chartText-unit {
  font-size: 13px !important;
  position: absolute;
  top: 52%;
  left: 51%;
}

.custom-grid-container {
  display: flex;
  flex-wrap: wrap;
}

.custom-grid-item {
  flex: 1 1 100%;
  margin-bottom: 20px;
}

/* .filter-img-class {
  display: none;
} */

.consumer-div {
  justify-content: end;
  gap: 20px;
}

.pop-datepicker {
  display: flex;
  align-items: center;
  justify-content: end;
}

.pop-datepicker-div {
  display: flex;
  gap: 10px;
}

.pop-datepicker-wrap1 {
  display: flex;
  gap: 10px;
}

.pop-datepicker-wrap2 {
  display: flex;
  gap: 10px;
}

/* .doughnut-chart1 {
  margin-bottom: 20px !important;
} */

.analyticsAgg-datePicker-overview {
  display: flex;
  gap: 12px;
  width: 100%;
  justify-content: end;
}

.overview-report-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
}

.overview-report-time {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.overview-date-picker-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
}

.overview-time-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
}

.overview-time-wrap label {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  margin: 0;
}

.overview-report-agg {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.overview-details {
  background-color: #fff;
  border-radius: 6px;
  padding: 10px 20px 10px 20px;
  box-shadow: 0px 6px 12px #aeaeae4f;
}

.overview-details-wrap {
  display: flex;
  align-items: normal;
  width: 100%;
}

.overview-details-wrap > div:first-child {
  width: 30%;
}

.overview-details > div:first-child {
  font-size: 14px;
  margin-bottom: 14px;
}

.overview-details-wrap h4 {
  font-size: 14px;
  margin: 0;
  padding: 4px;
}

.overview-details-wrap h6 {
  font-size: 14px !important;
  font-weight: normal;
  margin: 0;
  padding: 4px;
}

.overview-details .down-arrow {
  margin: 0 auto;
  display: flex;
  cursor: pointer;
}

.overview-details .up-arrow {
  margin: 0 auto;
  display: flex;
  cursor: pointer;
}
.report-overview-table {
  height: 300px;
  overflow-y: auto;
  overflow-x: auto;
}
@media (min-width: 1025px) and (max-width: 1230.98px) {
  .analytics-aggregation {
    margin-top: 20px;
    justify-content: end !important;
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .page-header-analytics {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: space-between !important;
    gap: 10px;
  }

  .analytics-production-btn {
    width: 100%;
  }

  .pop-datepicker-div {
    gap: 10px;
  }
}

@media (max-width: 576px) {
  .date-picker-production label {
    /* margin-top: -4px !important; */
    /* font-size: 12px !important; */
  }

  .overview-details {
    display: block !important;
  }
  .report-overview-table {
    height: 335px;
  }
}

@media (max-width: 899px) {
  .doughnut-chart1 {
    margin-bottom: 18px !important;
  }
}

@media (max-width: 767.98px) {
  .custom-grid-item {
    flex: 1 1 50%;
    max-width: 50%;
  }

  .Analytics-tabs {
    width: fit-content !important;
    height: 40px;
    padding: 0px 15px 0px 15px !important;
    font-size: 15px !important;
    letter-spacing: 0.5px;
  }

  .Reports-tabs {
    width: fit-content !important;
    height: 40px;
    padding: 0px 15px 0px 15px !important;
    font-size: 15px !important;
    letter-spacing: 0.5px;
  }

  .page-back-btn {
    font-size: 15px !important;
    gap: 4px !important;
  }

  .page-back-btn svg {
    width: 20px;
  }

  .page-back-btn span {
    width: max-content;
    font-size: 16px;
  }

  .Analytics-tabs-overview {
    width: fit-content !important;
    height: 40px !important;
    padding: 0px 15px 0px 15px !important;
    font-size: 15px !important;
    letter-spacing: 0.3px;
  }

  .Reports-tabs-overview {
    width: fit-content !important;
    height: 40px !important;
    padding: 0px 15px 0px 15px !important;
    font-size: 15px !important;
    letter-spacing: 0.3px;
  }

  .analysis-tabs-btn {
    flex-direction: column;
    justify-content: space-between !important;
    width: 100% !important;
    gap: 24px;
  }

  .analytics-aggregation {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: end;
    gap: 8px;
    margin-bottom: 20px;
  }

  .agg-div {
    justify-content: end;
    align-items: baseline;
  }

  .time-ref.overview {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }

  .time-ref.overview span {
    font-size: 16px;
    font-weight: 600;
  }

  .agg-input {
    padding: 8px !important;
    font-size: 14px;
  }

  .pop-datepicker-div {
    gap: 10px;
    flex-wrap: wrap;
    justify-content: end;
  }

  .pop-datepicker .popdate-picker-label {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }

  .pop-datepicker span {
    font-size: 14px;
    font-weight: 600;
  }

  .analytics-datepicker {
    flex-wrap: unset !important;
    flex-direction: unset !important;
    gap: 10px !important;
  }

  .doughnut-chart1 {
    height: auto !important;
  }

  .analyticsAgg-datePicker-overview {
    flex-direction: column-reverse;
    align-items: end;
  }

  .overview-report-wrap {
    flex-direction: row-reverse;
    gap: 10px;
  }

  .overview-time-wrap span {
    font-size: 14px;
    font-weight: 600;
  }

  .overview-time-wrap {
    gap: 4px;
  }

  .overview-date-picker-wrap {
    gap: 4px;
  }

  .overview-time-wrap svg {
    width: 15px;
  }

  .page-wraper-overview {
    padding-bottom: 0 !important;
  }

  .overview-details-wrap > div:first-child {
    width: 45%;
  }
}

.overview-details {
  display: none;
}

/* @media (min-width: 767px) and (max-width: 1024px) {
  .analysis-tabs-btn-analytics {
    justify-content: space-between;
    width: 100%;
    display: flex;
    flex-wrap: initial !important;
    padding-top: 11px;
  }

  .analytics-aggregation {
    margin-top: 20px;
  }

  .card-slider-table tr,
  td {
    padding-left: 5px;
  }

  .header-btn-date {
    width: 47% !important;
  }

  .header-btn-month {
    width: 47% !important;
  }

  .box-datepicker {
    width: 24% !important;
    margin-right: 12px;
  }

  .box-end-datepicker {
    margin-right: 11px !important;
  }

  .grid-overview-data {
    height: 161px !important;
  }
} */

/* @media (min-width: 1024px) and (max-width: 1536px) {
  .analysis-tabs-btn {
    justify-content: space-between;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 11px;
  }

  .analysis-tabs-btn-analytics {
    justify-content: space-between;
    width: 100%;
    display: flex;
    flex-wrap: initial !important;
    padding-top: 11px;
  }

  .analyticsAgg-datePicker {
    margin-top: 20px;
  }
} */

/* @media(max-width:899px) {
  .filter-img-class {
    display: none;
  }
} */
.filter-img-class {
  display: none;
}

.overview-card-header {
  display: none !important;
}

@media (max-width: 576.98px) {
  .overViewTableData {
    display: none;
  }

  .zoominbarchart-table {
    display: none !important;
  }

  .overview-table-header {
    display: none !important;
  }

  .overviewReport {
    padding: 8px 12px !important;
    font-size: 14px !important;
    width: 144px;
    height: 36px;
  }

  .Analytics-tabs {
    width: fit-content !important;
    height: 35px;
    padding: 0px 15px 0px 15px !important;
    font-size: 14px !important;
    letter-spacing: 0.5px !important;
  }

  .Reports-tabs {
    width: fit-content !important;
    height: 35px;
    padding: 0px 15px 0px 15px !important;
    font-size: 14px !important;
  }

  .filter-img-class {
    display: block !important;
  }
  .filter-img-class img {
    height: 36px;
  }
  .page-back-btn {
    font-size: 15px !important;
    gap: 4px !important;
  }

  .time-ref.overview span {
    font-size: 14px;
    font-weight: 600;
  }

  .page-back-btn svg {
    width: 18px;
    margin-right: 8px;
  }

  .page-back-btn span {
    width: max-content;
  }

  .Analytics-tabs-overview {
    width: fit-content !important;
    height: 40px !important;
    padding: 0px 10px 0px 10px !important;
    font-size: 14px !important;
    letter-spacing: 0.3px;
  }

  .Reports-tabs-overview {
    width: fit-content !important;
    height: 40px !important;
    padding: 0px 10px 0px 10px !important;
    font-size: 14px !important;
    letter-spacing: 0.3px;
  }

  .analysis-tabs-btn {
    flex-wrap: wrap !important;
    justify-content: space-between !important;
    width: 100% !important;
    gap: 8px;
  }

  .analytics-aggregation {
    margin-top: 8px;
    flex-wrap: wrap !important;
    flex-direction: column-reverse !important;
  }

  .analytics-datepicker {
    flex-wrap: unset !important;
    flex-direction: unset !important;
    gap: 10px !important;
  }

  .agg-div {
    justify-content: end;
    margin-top: 1px;
  }

  .agg-input {
    padding: 8px !important;
    font-size: 14px;
  }

  .AGG-dropdown {
    margin-top: 12px !important;
    font-size: 14px !important;
  }

  .page-header-analytics {
    /* flex-wrap: wrap; */
    gap: 10px;
  }

  .analytics-tabs-btn {
    display: flex;
    justify-content: space-between !important;
    width: 100% !important;
    gap: 12px;
  }

  .box-datepicker input {
    /* padding: 10px; */
    font-size: 12px;
  }

  .box-datepicke svg {
    width: 15px;
  }

  .header-btn-month input {
    /* padding: 10px; */
    font-size: 12px;
  }

  .header-btn-month svg {
    width: 15px;
  }

  .consumer-box {
    display: none;
  }

  .spinner-container {
    text-align: center;
  }

  .notFoundDataImg {
    transform: translate(0px, 50px) !important;
    /* height: 152px; */
  }

  .zoom-bar-chart h6 {
    font-size: 14px;
  }

  .zoom-bar-chart {
    padding-top: 0 !important;
  }

  .zoom-out-ico {
    flex-direction: row-reverse;
    justify-content: right;
  }

  .zoom-partition-div {
    height: 150px !important;
  }

  .zoom-out-ico img {
    margin-right: 15px;
    height: 24px;
    width: 24px;
  }

  .zoom-unit-title {
    flex-wrap: wrap;
  }

  .average-toggle {
    font-size: 14px;
  }

  .energy-div h6 {
    font-size: 14px;
    margin-top: 13px !important;
  }

  .energy-div {
    margin-bottom: 2px !important;
  }

  .time-toggle {
    flex-direction: row-reverse !important;
  }

  .pop-datepicker-box {
    width: none !important;
  }

  .analytics-aggregation {
    gap: 26px;
    margin-bottom: 0px;
  }

  .consumer-div .box-datepicker svg {
    width: 15px;
  }

  .pop-datepicker svg {
    width: 15px;
  }

  .pop-datepicker-div {
    flex-direction: column;
    gap: 0px;
  }

  .pop-datepicker-wrap1 {
    margin-bottom: 10px;
  }

  .pop-datepicker .popdate-picker-label {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }

  .pop-datepicker span {
    font-size: 14px;
    font-weight: 600;
  }

  .overview-time-wrap span {
    font-size: 14px;
    font-weight: 600;
  }

  .over-view-header {
    margin-top: -19px !important;
  }

  .custom-datepicker .MuiInputBase-root {
    height: 40px;
  }

  .custom-datepicker .MuiFormLabel-root {
    top: -5px;
    font-size: 14px;
  }

  .custom-datepicker .MuiInputLabel-shrink {
    top: 0;
  }

  .custom-datepicker svg {
    width: 15px;
  }

  .custom-datepicker input {
    font-size: 14px;
  }
}

.over-view-header {
  color: #001323;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

@media (max-width: 429.98px) {
  .page-back-btn span {
    font-size: 14px;
    letter-spacing: 0.4px;
  }

  .Analytics-tabs-overview {
    padding: 0px 6px 0px 6px !important;
    width: fit-content !important;
    height: 40px !important;
    font-size: 12px !important;
    letter-spacing: 0.5px;
  }

  .Reports-tabs-overview {
    padding: 0px 6px 0px 6px !important;
    width: fit-content !important;
    height: 40px !important;
    font-size: 12px !important;
    letter-spacing: 0.5px;
  }

  .Analytics-tabs {
    width: fit-content !important;
    height: 40px;
    padding: 0px 10px 0px 10px !important;
    font-size: 12px !important;
    letter-spacing: 0.5px;
  }

  .Reports-tabs {
    width: fit-content !important;
    height: 40px;
    padding: 0px 10px 0px 10px !important;
    font-size: 12px !important;
    letter-spacing: 0.5px;
  }

  .analytics-aggregation {
    gap: 20px;
  }

  .time-ref.overview span {
    font-size: 14px;
    font-weight: 600;
  }

  .agg-input {
    height: 35px;
  }

  .consumer-div {
    justify-content: end;
    gap: 6px;
  }

  .consumer-div .box-datepicker svg {
    width: 15px;
  }

  .pop-datepicker svg {
    width: 15px;
  }

  .pop-datepicker .popdate-picker-label {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }

  .pop-datepicker span {
    font-size: 14px;
    font-weight: 600;
  }

  .pop-datepicker-div {
    flex-direction: column;
  }

  .pop-datepicker-wrap1 {
    margin-bottom: 10px;
  }

  .custom-datepicker .MuiInputBase-root {
    height: 40px;
  }

  .custom-datepicker .MuiFormLabel-root {
    top: -5px;
    font-size: 14px;
  }

  .custom-datepicker .MuiInputLabel-shrink {
    top: 0;
  }

  .custom-datepicker svg {
    width: 15px;
  }
}

@media (min-width: 431px) and (max-width: 576px) {
  .export-btn {
    margin-top: 0 !important;
  }

  .consumerCard {
    position: absolute;
    top: 28%;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
  }

  .consumer-card-box {
    min-height: 636px !important;
    width: 92%;
  }

  label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementTop.time-ref.css-1hpqosy-MuiFormControlLabel-root {
    flex-direction: row-reverse;
  }

  .chart-div h6 {
    font-size: 14px;
  }

  .zoom-chart-div img {
    height: 19px;
    width: 19px;
    margin-top: -8px;
  }
}

@media (min-width: 200px) and (max-width: 430px) {
  .export-btn {
    margin-top: 0 !important;
  }

  .consumer-card-box {
    min-height: 607px !important;
    width: 100%;
  }

  .consumerCard {
    position: absolute;
    top: 31%;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
  }

  label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementTop.time-ref.css-1hpqosy-MuiFormControlLabel-root {
    flex-direction: row-reverse;
    margin: 0;
  }

  .chart-div h6 {
    font-size: 14px;
  }

  .zoom-chart-div img {
    height: 19px;
    width: 19px;
    margin-top: -8px;
  }
}

@media (min-width: 767px) and (max-width: 1024px) {
  .analytics-aggregation {
    margin-top: 20px;
    justify-content: end !important;
    width: 100%;
  }
}

@media (max-width: 599.98px) {
  .overview-card-header {
    display: block !important;
  }

  .overview-table-header {
    display: none !important;
  }
}
