.addDevice-button.bridge {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  float: right;
}
.device-tagunit {
  width: 20%;
}
.checked-icon {
  height: 20px !important;
}
.Configure-table td{
  padding: 7px !important;
}
.addsoftTag-cardModal {
  height: auto;
  width: 25%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 26px #888888 !important;
  border-radius: 6px !important;
  z-index: 120;
  min-width: 400px;
}
.softTag-title {
  font-size: 14px;
  margin-bottom: 0px;
}
.scientific-calc-btn {
  background: #1c407b;
  border-radius: 2px;
  color: white;
  padding: 8px 14px;
  border: 1px solid #1c407b;
}
.addTag-box {
  width: 179px;
  font-size: 14px;
  padding: 10px;
  background: white;
  border-radius: 4px;
}

.softTag-btn {
  background: #ffffff;
  border: 1px solid #1c407b;
  border-radius: 4px;
  padding: 8px 20px;
  margin-right: 13px;
}
.nullgroup{
  background: #ffffff;
  margin-top: 20px ;
}

/* .deviceManagementTable1 tr td:nth-child(1){
    max-width: 10px;
}*/

/* .deviceManagementTable1 tr td:nth-child(3){
    max-width: 10px;
}  */
.deviceManagementTable1 tr td:nth-child(4) {
  max-width: 10px;
}
.deviceManagementTable1 tr td:nth-child(5) {
  max-width: 10px;
}
.deviceManagementTable1 tr td:nth-child(6) {
  max-width: 10px;
}
.deviceManagementTable1 tr td:nth-child(7) {
  max-width: 10px;
}
.deviceManagementTable1 tr td:nth-child(8) {
  max-width: 10px;
}
.deviceManagementTable1 tr td:nth-child(9) {
  min-width: 394px;
  border-collapse: separate;
  overflow: visible;
}
.deviceManagementTable1 tr td:nth-child(10) {
  min-width: 394px;
}
.select-softTag-modal {
    width: 944px;
    overflow: auto;
    height: 614px;
    background-color: white;
    position: fixed;
    top: 17%;
    left: 30%;
    transform: translate(-50px, -50px);
    z-index: 999;
    box-shadow: 0px 0px 30px #0000008a;
    border-radius: 6px;
    padding: 20px;
}
/* .deviceManagementTable1 tr td:nth-child(11){
    min-width: 40px;
}
.deviceManagementTable1 tr td:nth-child(12){
    min-width: 40px;
}
.deviceManagementTable1 tr td:nth-child(13){
    min-width: 20px;
}
.deviceManagementTable1 tr td:nth-child(14){
    min-width: 20px;
}  */

.deviceManagementTitle {
  color: #222d39;
  font-size: 16px !important;
  font-weight: 500 !important;
  padding-left: 10px;
  cursor: pointer;
  margin-bottom: 0px !important;
}

.configure-input {
  background-color: transparent;
  border: 0;
  color: #000 !important;
  width: 100%;
}
.configure-input-1 {
  background-color: transparent;
  border: 0;
  color: #000 !important;
}
.configure-input-msg {
  background-color: transparent;
  border: 0;
  color: #000 !important;
  width: 100%;
}
.configure-input-msg-unit {
  background-color: transparent;
  border: 0;
  color: #000 !important;
  width: 67px;
}

.configure-input:focus-visible {
  outline: none !important;
}

.device-management.tagWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.device-managemnet-Btnwrap {
  display: flex;
  align-items: center;
}

.device-managemnegt.btnwrap {
  display: flex;
  align-items: center;
}
.device-mangement.unlockImg {
  height: 15px;
}

.device-managemnet.unlock-btn {
  background-color: #1c407b;
  border: 1px solid #1c407b !important;
  border-radius: 4px 0px 0px 4px;
  color: #fff;
  border: 0;
  padding: 8px 20px 8px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.device-managemnet.unlock-button {
  background-color: #fff;
  border: 1px solid #1c407b !important;
  border-radius: 4px 0px 0px 4px;
  color: #1c407b;
  border: 0;
  padding: 8px 20px 8px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.device-management.lock-btn {
  background-color: #fff;
  border: 1px solid #1c407b !important;
  border-radius: 0px 4px 4px 0px;
  color: #1c407b;
  border: 0;
  padding: 8px 20px 8px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.device-management.lock-button {
  background-color: #1c407b;
  border: 1px solid #1c407b !important;
  border-radius: 0px 4px 4px 0px;
  color: #fff;
  border: 0;
  padding: 8px 20px 8px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

/* .tableResponsive {
    max-height: 70vh;
    overflow-y: auto;
    max-height: 300px;
}

.deviceManagementTable thead th {
    position: sticky !important;
    top: 0 !important;
    background-color: red;
} */

/* .stickyHeader {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: red;
} */

.confi-table-group {
  overflow-x: scroll;
}
.confi-table-40 {
  position: sticky;
  left: 0;
  z-index: 100;
}
.edit-column:first-child {
  background-color: #eeefef !important;
}
.confi-table-group {
  max-height: 70vh;
  border: 1px solid #a9b0bd !important;
  border-radius: 6px !important;
  padding: 0;
}

.confi-table-group table {
  border-radius: 0 !important;
  height: 100%;
  overflow-y: auto;
}

.confi-table-group table th {
  white-space: nowrap;
}

.confi-table-40 {
  /* min-width: 380px;
    max-width: 380px;
    width: 40% !important;
    overflow-y: auto; */
}

/* ========= */

.confi-table-group .confi-table-40 table {
  border: none !important;
}

.confi-table-40 .deviceManagementTable table {
  border: 0 !important;
}

/* ========= */
.configure-red {
  background-color: #ff0000e8;
}

.confi-table-40 table tbody tr:nth-child(even),
.confi-table-40 table tbody tr:nth-child(odd) {
  background-color: #eeefef !important;
}

.confi-table-40-red table tbody tr:nth-child(even),
.confi-table-40-red table tbody tr:nth-child(odd) {
  background-color: #ff0000e8 !important;
}

.confi-table-40 table tbody tr:nth-child(even):hover,
.confi-table-40 table tbody tr:nth-child(odd):hover,
.confi-table-60 {
  flex: 1;
  /* overflow-y: auto; */
}

.confi-table-60 table tbody tr:nth-child(even),
.confi-table-60 table tbody tr:nth-child(odd) {
  background-color: #e3e9f2 !important;
}

.confi-table-60-red table tbody tr:nth-child(even),
.confi-table-60-red table tbody tr:nth-child(odd) {
  background-color: #ff0000e8 !important;
}

/* ==================================================================================== */

/* .confi-table-60 table tbody tr:nth-child(even) td:nth-child(n+6),
.confi-table-60 table tbody tr:nth-child(odd) td:nth-child(n+6) {
    background-color: #F9ECE5 !important;
} */

.addDevice-button-disable {
  background-color: #1c407ba6 !important;
  color: #ffffff96;
  border-radius: 4px;
  width: 150px;
  border: 0;
  text-transform: capitalize;
}

.bgred {
  /* background-color: red !important; */
  /* background-color: #ff0000ba !important; */
  background-color: #ff0000ab !important;
}

.confi-table-60.tab table tbody tr:nth-child(even) td:nth-child(n + 6),
.confi-table-60.tab table tbody tr:nth-child(odd) td:nth-child(n + 6) {
  background-color: #f9ece5;
}

.confi-table-60-red.tab table tbody tr:nth-child(even) td:nth-child(n + 6),
.confi-table-60-red.tab table tbody tr:nth-child(odd) td:nth-child(n + 6) {
  background-color: #ff0000e8 !important;
}

.confi-table-60.alarm table tbody tr {
  background-color: #f9ece5 !important;
}

/* ==================================================================================== */

.confi-table-group ::-webkit-scrollbar {
  display: none;
}

.confi-table-group table thead th {
  position: sticky;
  z-index: 1;
  background-color: #fff;
  border-bottom: 0px !important;
  top: -1px;
  padding: 0px !important;
}

.confi-table-group table thead th > div {
  border-bottom: 1px solid #a9b0bd !important;
  padding: 16px 20px !important;
  width: 100%;
  margin-left: 0px;
  border-right: 1px solid #a9b0bd !important;
}

.confi-tabs {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.confi-table-group table tbody tr:first-child td {
  border-top: 0px !important;
}

.addDevice-button.bridge.import {
  position: relative;
}

.importCSVModal {
  /* height: auto; */
  min-height: 150px;
  width: 400px;
  padding: 30px 30px;
  position: absolute;
  /* top: 30%; */
  right: 2%;
  /* top: 40%; */
  /* left: 50%; */
  transform: translate(0%, 10%);
  box-shadow: 0px 4px 26px #888888 !important;
  z-index: 99;
}

.importCSVModal-text {
  text-align: center;
  color: #1c407b;
  margin-bottom: 20px;
  font-size: 500;
}

.ImportCSV.Input {
  /* width: 50%; */
  margin-bottom: 20px;
}

.ImportCSV-BtnWrap {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.ImportCSV-AddBtn {
  background-color: #1c407b;
  color: #fff;
  border: 1px solid #1e3760;
  border-radius: 4px;
  width: 120px;
  text-transform: capitalize;
  padding: 8px;
  /* margin-right: 22px;    */
}

.ImportCSV-CancelBtn {
  background-color: #1c407b;
  color: #fff;
  border: 1px solid #1e3760;
  border-radius: 4px;
  width: 120px;
  text-transform: capitalize;
  padding: 8px;
  /* margin-right: 22px;    */
}

.tagConfigurationModal-card {
  /* height: auto; */
  width: 400px;
  position: absolute;
  height: 500px;
  overflow: auto !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 26px #888888 !important;
  border-radius: 0px 6px 6px 0px !important;
  z-index: 99;
}

.tagConfiModal-title {
  color: #1c407b;
  /* color: #fff; */
  font-weight: 500 !important;
}

.tagConfigurationModal-titleWrap {
  padding: 0;
  text-align: center !important;
  /* background-color: #1C407B; */
  padding: 24px 0px 14px 0px;
  position: sticky;
  top: 0;
  background-color: #fff;
  /* padding: 10px 10px 34px 10px; */
  z-index: 99;
}

.tagConfigurationData-innerWrap {
  padding: 0px 18px 0px 25px;
}

.addTagData-submitBtnWrap {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 99;
}

.addTagDataBtn {
  background-color: #1c407b;
  color: #fff;
  border: 1px solid #1e3760;
  border-radius: 4px;
  /* width: auto; */
  width: 100%;
  padding: 12px 82px;
  margin-top: 20px;
  letter-spacing: 1.8px;
  text-transform: capitalize;
}

.device-management-tag {
  display: flex;
  gap: 20px;
}
.device-dropdown {
  width: 243px;
  border-radius: 4px;
}


.Fx-btn{
  background: #ffffff;
      border: 1px solid #1c407b;
      border-radius: 4px;
      padding: 1px 5px;
      margin-right: 13px;
      color: #1C407B;
      font-weight: 700;
  }
  .Flex{
    display: flex !important;
    border: none !important;
  }
  .Tag_Name_container{
    padding: 14px !important;
  }



.configure-tag_name:focus-visible {
  outline: none !important;
  outline-offset: 0 !important;
}

@media (min-width: 899px) and (max-width: 1199px) {
  .importCSVModal {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (min-width: 767px) and (max-width: 899px) {
  .importCSVModal {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (min-width: 767px) and (max-width: 1024px) {
  .deviceManagementHeader-wrap.configure {
    margin-bottom: 20px;
  }
  .device-management-tag{
    flex-wrap: wrap;
  }
  .device-dropdown{
    height: 50px;
  }
  .addsoftTag-cardModal{
    width: auto;
    z-index: 999;
  }
  .select-softTag-modal{
    width: auto;
    left: 16%;
  }
}

@media (min-width: 767px) and (max-width: 790px) {
  .deviceManagementTitle {
    color: #222d39;
    font-size: 15px !important;
    font-weight: 500 !important;
    padding-left: 0px;
    cursor: pointer;
  }
}

@media(min-width: 1024px) and (max-width: 1536px){
    .select-softTag-modal {
        overflow: auto;
        height: 544px;
        left: 15%;
        right:15%
    }
}


@media(min-width: 600px) and (max-width: 830px){
  .select-softTag-modal {
      overflow: auto;
      height: 574px;
      left: 23%;
      right:20%;
      width:500px;
  }
  .Softtag_model-container{
    display: flex !important;
    flex-direction: column !important;
  }
  .popper-container{
    width:395px !important;
  }
  .addsoftTag-cardModal{
    top: 33% !important;
  }
}
