.page-back-btn {
  color: #001323;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 14px;
  cursor: pointer;
}

.timeToggle {
  text-align: center;
  margin-right: 14px;
}

.timeToggle h6 {
  font-weight: 400 !important;
}

.selectMachine-dropdown {
  margin-right: 17px;
  padding: 12px 8px;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
  border: 1px solid #475467;
  width: 155px;
}

.page-header .header-btn-group {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 0;
  flex-wrap: wrap;
}

.vms-device-unit {
  height: 268px;
  overflow: auto;
}

.overviewReport {
  background: #ffffff;
  border: 1px solid #475467;
  border-radius: 4px;
  padding: 10px;
  font-size: 15px;
}

.setvms-COCrange-popup {
  width: 117px;
  border-radius: 4px;
  border: 1px solid #1E3760;
  background: white;
  height: 58px;
}


.spinner-container {
  position: relative;
  /* Add any other styles for the container */
}

.notFoundDataImg {
  margin-left: auto;
  margin-right: auto;
  display: block;
  transform: translate(-50px, -8px);
}

.notFoundDataImgPie {
  margin-left: auto;
  margin-right: auto;
  display: block;
  transform: translate(-50px, -4px);
}

.notFoundDataImgReport {
  margin-left: auto;
  margin-right: auto;
  display: block;
  transform: translate(-50px, 100px);
}

.overView-data-table {
  min-width: 650px;
  border-collapse: separate !important;
  border-spacing: 0;
}

.overView-data-table td:first-child,
.overView-data-table td:nth-child(2) {
  position: sticky;
  left: 0;
  background-color: #fff;
  border-collapse: separate;
  /* Add a higher z-index value for the sticky first child */
  z-index: 0;
}

.MuiPaper-root.MuiTableContainer-root.table-overview-data.MuiPaper-elevation1.MuiPaper-rounded {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 99;
  overflow-x: unset !important;
}

.overView-data-table td:nth-child(2) {
  left: 84px;
}

.overView-data-table tr:nth-child(even) td {
  background-color: #fff;
}

.overView-data-table tr:nth-child(odd) td {
  background-color: #f4f7fe;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  /* Add any other styles for the loader */
}

.configrationOverviewTable td {
  padding: 5px;
}

.configrationOverviewTable th {
  padding: 15px 19px;
}

.addButton {
  background: #1c407b;
  border: 1px solid #1e3760;
  border-radius: 4px;
  color: white;
  padding: 12px 27px;
}

.spinner-graph {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* z-index: 1; */
}

.Analytics-tabs {
  background: #1c407b;
  border: 1px solid #103168;
  color: white;
  padding: 10px;
  width: 200px;
  font-weight: 500;
  font-size: 18px;
}

.Reports-tabs {
  background: white;
  border: 1px solid #103168;
  color: #103168;
  padding: 10px;
  width: 200px;
  font-weight: 500;
  font-size: 18px;
}

/* .Analytics-tabs-overview {
  background: #1c407b;
  border: 1px solid #103168;
  color: white;
  padding: 10px;
  width: 200px;
  font-weight: 500;
  font-size: 15px;
} */

.Reports-tabs-overview {
  background: white;
  border: 1px solid #001323;
  color: #001323;
  padding: 10px;
  width: 200px;
  font-weight: 500;
  font-size: 16px;
}

.form-input-class option {
  padding: 10px;
}

.MuiTab-textColorInherit.Mui-selected {
  background: #1c407b;
  border: 1px solid #103168;
  color: #fff;
}

.AGG-dropdown {
  margin-top: 17px;
  margin-right: 9px;
  color: #001323;
  font-size: 16px;
  font-weight: 400;
}

.consumerData-box {
  background-color: #fff;
  min-height: 680px;
  border-radius: 6px;
  padding: 10px;
}

.productionAvailibility-box {
  background-color: #fff;
  min-height: 715px;
  border-radius: 6px;
  padding: 10px;
}

.consumer-text-disc {
  background-color: #f5f5f5;
  padding: 6px 10px;
}

.datepicker-color .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #c0255f !important;
}

.datepicker-color .MuiOutlinedInput-notchedOutline.css-igs3ac {
  border-color: #c0255f !important;
}

.second-datepicker-color .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #e66595 !important;
}

.second-datepicker-color .MuiOutlinedInput-notchedOutline.css-igs3ac {
  border-color: #e66595 !important;
}

.datepicker-color .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-1bn53lx {
  color: #c0255f !important;
}

.datepicker-color .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  color: #c0255f !important;
}

.second-datepicker-color .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-1bn53lx {
  color: #e66595 !important;
}

.second-datepicker-color .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  color: #e66595 !important;
}

.datepicker-color .css-1ald77x {
  color: #c0255f !important;
}

.datepicker-color .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  color: #c0255f !important;
}

.second-datepicker-color .css-1ald77x {
  color: #e66595 !important;
}

.second-datepicker-color .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  color: #e66595 !important;
}

.datepicker-color .css-p0rm37 {
  color: #c0255f !important;
}

.datepicker-color .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #c0255f !important;
}

.second-datepicker-color .css-p0rm37 {
  color: #e66595 !important;
}

.second-datepicker-color .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #e66595 !important;
}

.horizontal-line {
  height: 10px;
  background-color: #f5f5f5;
  /* width: 60%; */
  margin: 0 auto;
}

.consumer-text-disc1 {
  background-color: #f5f5f5;
  padding: 8px;
}

.consumer-text {
  background-color: #efeffd;
  padding: 6px;
}

.consumer-text-disc h6 {
  font-size: 14px;
}

.add-date {
  display: -webkit-inline-box;
}

.Title {
  font-size: 14px !important;
}

.search-textfield {
  padding-top: 13px !important;
  width: 100%;
}

.furnace {
  /* max-height: 150px; */
  max-height: 173px;
  overflow: auto;
}

.device-units {
  /* max-height: 100px; */
  max-height: 112px;
  overflow: auto;
}

.export-btn {
  background-color: #001323;
  color: #fff;
  border-radius: 4px;
  padding: 15px;
  width: 100%;
  margin-top: 11px;
}

.overViewTableData tr:nth-child(odd) {
  background-color: #f4f7fe;
}

.overViewTableData td {
  padding: 5px 20px;
}

table.MuiTable-root.overView-data-table.overView-table-data1 td {
  padding: 4px 24px !important;
}

table.MuiTable-root.overView-data-table.overView-table-data1 th {
  padding: 4px 24px !important;
}

.overViewTableData th {
  padding: 0px 18px;
}

.end-date-picker {
  margin-left: 17px !important;
}

.analytics-tabs-btn {
  display: flex;
}

.analytics-aggregation {
  display: flex;
}

.analysis-tabs-btn {
  justify-content: space-between;
  width: 100%;
  display: flex;
  padding-top: 11px;
}

.analysis-tabs-btn-analytics {
  justify-content: space-between;
  width: 100%;
  display: flex;
  padding-top: 11px;
}

.Grid-text1 {
  /* padding: 4px 13px; */
  /* background-color: #efeffd; */
}

.overview-table td {
  /* padding: 10px 13px !important; */
  padding: 0;
  border: none !important;
  border-right: 1px solid #cbc3c3 !important;
  font-size: 16px;
  font-weight: 700;
}

/* .overview-table td:first-child{
  padding: 0px 0px !important;
} */
table.MuiTable-root.overview-table.overview-table1.css-rqglhn-MuiTable-root td {
  padding: 0;
}

.zoomchart-td {
  padding-left: 0 !important;
}

.overview-table td:last-child {
  border-right: none !important;
}

.overview-table th:last-child {
  border-right: none !important;
}

.overview-table th {
  border: none !important;
  border-right: 1px solid #cbc3c3 !important;
  background-color: #f5f5f5;
  font-size: 16px;
}

.overview-table tr {
  border: none !important;
}

.overview-table {
  border: none !important;
  border-radius: 0 !important;
}

.overview-table tr:nth-child(even) {
  background-color: #f5f5f5;
}

.grid-switch-unit span {
  float: right !important;
}

.overview-zoom-box {
  width: 100%;
  height: 727px;
  background: white;
  border-radius: 6px;
  box-shadow: 0px 0px 20px #6d6d6d1a;
}

.Title-desc {
  margin-bottom: 0 !important;
}

.consumption-switch1 {
  background-color: red;
}

.consumption-switch2 {
  background-color: green;
}

.consumption-switch3 {
  background-color: yellow;
}

.consumption-switch4 {
  background-color: red;
}

/* div.Grid-text:nth-child(1) {
  border-left: 4px solid #9b64b7;
  margin-bottom: 20px;
} */
/* div.Grid-text:nth-child(2) {
  border-left: 4px solid #e579a0;
}
div.Grid-text:nth-child(3) {
  border-left: 4px solid #fea968;
}
div.Grid-text:nth-child(4) {
  border-left: 4px solid #fea968;
}
div.Grid-text:nth-child(5) {
  border-left: 4px solid #9b64b7;
} */
.doughnut-chartText-unit {
  font-size: 13px !important;
  position: absolute;
  top: 52%;
  left: 51%;
}

/* CSS styles in a separate stylesheet or in the <style> tag */

/* Define the custom classname for the responsive layout */
.custom-grid-container {
  display: flex;
  flex-wrap: wrap;
}

.custom-grid-item {
  flex: 1 1 100%;
  margin-bottom: 20px;
}

.vmsTagListBox {
  background-color: #fff;
  min-height: 930px;
  border-radius: 6px;
  padding: 10px;
}

/* Media query for screens with a minimum width of 768px */
@media (min-width: 768px) {
  .custom-grid-item {
    flex: 1 1 50%;
    max-width: 50%;
  }
}

@media (min-width: 767px) and (max-width: 1024px) {
  .analysis-tabs-btn {
    justify-content: space-between;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 11px;
  }

  .analysis-tabs-btn-analytics {
    justify-content: space-between;
    width: 100%;
    display: flex;
    gap: 20px;
    padding-top: 11px;
  }



  .card-slider-table tr,
  td {
    padding-left: 5px;
  }

  /* .header-btn-date {
    width: 47% !important;
  } */

  .header-btn-month {
    /* width: 50% !important; */
    /* margin-right: 11px; */
    /* width: 47% !important; */
  }

  /* .box-datepicker {
    width: 24% !important;
    margin-right: 12px;
  } */

  .box-end-datepicker {
    margin-right: 11px !important;
  }

  .grid-overview-data {
    height: 161px !important;
  }
}

@media (min-width: 1024px) and (max-width: 1536px) {
  .analysis-tabs-btn {
    justify-content: space-between;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 11px;
  }

  .analysis-tabs-btn-analytics {
    justify-content: space-between;
    width: 100%;
    display: flex;
    flex-wrap: initial !important;
    padding-top: 11px;
  }

  .analyticsAgg-datePicker {
    margin-top: 20px;
  }
}


@media(max-width:576px) {
  .overview-zoom-box {
    height: auto;
  }
}