.dashboardCard {
  /* padding: 30px 0px; */
  /* border: 2px dashed #1C407B; */
  border: 2px dashed #1c407b9e;
  box-shadow: 0px 0px 20px #6d6d6d1a;
  opacity: 1;
  border-radius: 12px !important;
}

.dashboardCard-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 22px;
  cursor: pointer;
  margin: 25px 25px;
  position: relative;
}

.dashboardCard-item::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 50px; /* Set the fixed height here */
  border-right: 2px solid rgb(201, 207, 231);
}

.grid-item:last-child .dashboardCard-item::after {
  display: none; /* Hide the border-right for the last child */
}
.dashboardCard-text {
  display: flex;
  align-items: start;
  flex-direction: column;
}

.dashboardCard-item:not(:last-child) {
  /* border-right: 2px solid rgb(201, 207, 231); */
}

.dashboardCard-item:nth-child(4n) {
  /* border-right: none; */
}

/* .dashboardCard-item:nth-last-of-type(4) {
    border-right: none;
} */

/* .dashboardCard-item:not(:last-child) {
    border-right: 2px solid rgb(201, 207, 231) !;
} */
.dashboard-card-img {
  /* width: 10%; */
}

.dashboardCard-img {
  height: 50px;
}

.dashboardCard-item1 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  cursor: pointer;
  margin: 25px 0;
}

.dashboardCard-title {
  color: #001323;
  font-size: 20px !important;
  font-weight: 500 !important;
  margin: 0 !important;
}

.dashboardCard-number {
  text-align: left;
  color: #001323;
  /* font-size: 40px !important; */
  font-size: 30px !important;
  font-weight: 600 !important;
  margin-bottom: 0 !important;
}

.dashboardItem {
  display: flex;
  justify-content: center;
}

.Header-TextShimmer {
  margin: 8px auto;
}

.Header-TextShimmer .shimmer-text-line {
  padding: 15px 0px !important;
  width: 50% !important;
}

/* ======================== */

.dashboardCard-plant {
  background: transparent
    linear-gradient(180deg, #c9cfe7 0%, #e6e9f4 24%, #ffffff 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 12px 20px #aaaaaa29;
  border: 1px solid #e6eaf2;
  border-radius: 8px !important;
  /* padding: 22px 18px; */
  /* padding: 10px 18px; */
  padding: 10px 10px 10px 10px;
  cursor: pointer;
  transition: 0.5s !important;
}

.dashboardCard-plant:hover {
  box-shadow: 0px 12px 20px #aaaaaac4 !important;
  transform: translateY(-3px) !important;
}

.dashboardCard-plantTitle {
  color: #001323;
  font-size: 24px !important;
  font-weight: 500 !important;
}

.dashboardCard-plantsubTitle {
  color: #001323;
  margin-bottom: 20px !important;
}

.dashboardCard-plantContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.dashboardCard-plantText {
  color: #001323;
  font-size: 12px !important;
  font-weight: 500 !important;
  margin: 0;
}

.dashboardCard-plantNumber1 {
  color: #50c878;
  font-size: 26px !important;
  font-weight: 800 !important;
}

.dashboardCard-plantNumber2 {
  color: #e31e24;
  font-size: 26px !important;
  font-weight: 800 !important;
}

.dashboardCard-plantSmalltext {
  color: #5473a5;
}

.dashboardCard-plantImg {
  width: 24px;
  object-fit: cover;
  margin: 0 auto !important;
}

.dashboardCard-plantImg.alertImg {
  width: 20px;
}

.dashboardCard-plantSmalltext {
  font-size: 10px !important;
}

/* @media(min-width:1200px) and (max-width:1500px) {
    .dashboardCard-plantContent {
        gap: 12px !important;
        justify-content: inherit !important;
    }
    .dashboardCard-plantImg {
        width: 26px;
    }
    .dashboardCard-plantText {
        font-size: 12px !important;
    }
    .dashboardCard-plantNumber1 {
        font-size: 16px !important;
    }
    .dashboardCard-plantNumber2 {
        font-size: 16px !important;
    }
    .dashboardCard-plantSmalltext {
        font-size: 10px !important;
    }
} */

@media (min-width: 1199.98px) and(max-width:1400px) {
  .dashboardCard-plant {
    padding: 0px 0px !important;
  }

  .dashboardCard-plantText {
    font-size: 13px !important;
  }

  .dashboardCard-plantImg {
    width: 24px !important;
  }

  .dashboardCard-plantImg.alertImg {
    width: 20px !important;
  }

  .dashboardCard-plantNumber1 {
    font-size: 20px !important;
  }

  .dashboardCard-plantNumber2 {
    font-size: 20px !important;
  }

  .dashboardCard-plantSmalltext {
    font-size: 10px !important;
  }

  .dashboardCard-number {
    font-size: 30px !important;
  }
}

@media (min-width: 899.98px) and (max-width: 1024px) {
  .dashboardCard-plant {
    padding: 14px 0px 14px 8px !important;
  }

  .dashboardCard-title {
    font-size: 18px !important;
    text-align: center;
  }

  .dashboardCard-number {
    font-size: 25px !important;
    text-align: center;
  }

  /* ======================= */
  .dashboardCard-plantContent {
    /* width: 150%; */
    gap: 12px !important;
    justify-content: inherit !important;
  }

  .dashboardCard-plantImg {
    /* width: 26px; */
    width: 20px;
  }

  .dashboardCard-plantImg.alertImg {
    width: 26px;
  }

  .dashboardCard-plantText {
    font-size: 12px !important;
  }

  .dashboardCard-plantNumber1 {
    font-size: 20px !important;
  }

  .dashboardCard-plantNumber2 {
    font-size: 20px !important;
  }

  .dashboardCard-plantSmalltext {
    font-size: 12px !important;
  }
}

@media (min-width: 767px) and (max-width: 899.98px) {
  .dashboardCard-img {
    width: 42px;
  }

  .dashboardCard-title {
    font-size: 14px !important;
  }

  .dashboardCard-number {
    font-size: 20px !important;
    text-align: center;
  }

  .dashboardCard-item {
    gap: 14px;
    flex-direction: unset;
  }

  /* ============================================= */
  .dashboardCard-plantSmalltext {
    font-size: 8px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .dashboardCard-item {
    margin: 25px 0px;
  }
}

@media (max-width: 1199px) {
  .dashboardCard-item {
    flex-direction: column;
  }

  .dashboardCard-text {
    align-items: center;
  }

  .dashboardCard-item {
    margin: 25px 0px;
  }
}

@media (max-width: 1024px) {
  .dashboardCard-item {
    flex-direction: column;
  }

  .dashboardCard-text {
    align-items: center;
  }

  .dashboardCard-item {
    margin: 25px 0px;
  }
}

@media (max-width: 899px) {
  .dashboardCard-img {
    height: 45px;
  }

  .dashboardCard-item {
    justify-content: start;
    flex-direction: unset;
    margin: 20px 20px;
    gap: 40px;
  }

  .dashboardCard-title {
    font-size: 16px !important;
  }

  .dashboardCard-number {
    font-size: 26px !important;
  }

  .dashboardCard-text {
    align-items: start;
  }

  .dashboardCard-plantSmalltext {
    font-size: 14px !important;
  }
}

@media (max-width: 767px) {
  .dashboardCard-img {
    height: 45px;
  }

  .dashboardCard-item {
    justify-content: start;
    margin: 20px 20px;
    gap: 40px;
    flex-direction: unset;
  }

  .dashboardCard-title {
    font-size: 16px !important;
  }

  .dashboardCard-number {
    font-size: 26px !important;
  }

  .dashboardCard-plantNumber1 {
    font-size: 24px !important;
  }

  .dashboardCard-plantNumber2 {
    font-size: 24px !important;
  }

  .dashboardCard-plantSmalltext {
    font-size: 12px !important;
  }
}

@media (max-width: 576px) {
  .dashboardCard-img {
    height: 40px;
  }

  .dashboardCard-item {
    justify-content: start;
    margin: 20px 20px;
    gap: 35px;
  }

  .dashboardCard-title {
    font-size: 12px !important;
  }

  .dashboardCard-number {
    font-size: 20px !important;
  }

  .dashboard-card-img {
    width: 10%;
  }

  .dashboardCard-plantTitle {
    font-size: 18px !important;
  }

  .dashboardCard-plantsubTitle {
    font-size: 10px !important;
  }

  .dashboardCard-plantNumber1 {
    font-size: 24px !important;
  }

  .dashboardCard-plantNumber2 {
    font-size: 24px !important;
  }

  .dashboardCard-plantSmalltext {
    font-size: 8px !important;
  }
  .grid-item:nth-child(2) .dashboardCard-item::after {
    display: none; /* Hide the border-right for the second child */
  }
}
@media (max-width: 899px) {
    .grid-item:nth-child(2) .dashboardCard-item::after {
        display: none; 
      }
}

@media (max-width: 400px) {
  .dashboardCard-img {
    height: 30px;
  }

  .dashboardCard-item {
    justify-content: start;
    margin: 20px 12px;
    gap: 30px;
  }

  .dashboardCard-title {
    font-size: 12px !important;
  }

  .dashboardCard-number {
    font-size: 20px !important;
  }

  .dashboard-card-img {
    width: 10%;
  }
}
